.material-icon{
    width: 30px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    img{
        width: auto;
        position: absolute;
        height: 30px;
        top: 50%;
        margin-top: -50%;
        right: 10px;
    }
}

.icon-loading{
    width: 100%;
    height: 100%;
    .circulo{
        background: #fff;
    }
}